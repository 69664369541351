import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { useParams } from "react-router-dom";
import SideBar1 from "../Components/SideBar1";

const StudentUpdate1 = () => {
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = useState([]);
  const { ID } = useParams(); // Destructure id from useParams

  const [formData, setFormData] = useState({
    NAME: "",
    SURNAME: "",
    SEX: "",
    GRADE: "",
    AGE: "",
    PHONE_NUMBER: "",
    GUARDIAN_NAME: "",
    GUARDIAN_PHONE_NUMBER: "",
    RELATIONSHIP: "",
    ADDRESS: "",
    CITY: "",
    STATE: "",
    STATUS: "",
    PACKAGE_TYPE: "",
    PACKAGE_NAME: "",
    SPORT_TYPE: "",
    DURATION: "",
    START_DATE: "",
    END_DATE: "",
    RENEWED: "",
    PRICE: "",
    DISCOUNT: "",
    TOTAL_AMOUNT: "",
  });

  useEffect(() => {
    // Fetch existing data based on ID and set the form data
    axios
      .get(`http://localhost:5002/student/${ID}`)
      .then((response) => {
        setFormData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [ID]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`http://localhost:5002/student/${ID}`, formData)
      .then((response) => {
        console.log(response.data);
        window.alert("User updated successfully");
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        window.alert("Error updating user");
      });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update form data
    setFormData({
      ...formData,
      [name]: value, // Parse value to float
    });

    // Calculate total amount
    if (name === "PRICE" || name === "DISCOUNT") {
      const price = name === "PRICE" ? parseFloat(value) : formData.PRICE;
      const discount =
        name === "DISCOUNT" ? parseFloat(value) : formData.DISCOUNT;
      const totalAmount = price * (1 - discount / 100);
      setFormData((prevFormData) => ({
        ...prevFormData,
        TOTAL_AMOUNT: totalAmount.toFixed(2), // Rounded to 2 decimal places
      }));
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []); // Fetch options on component mount

  const fetchOptions = () => {
    axios
      .get("http://localhost:5002/api/options")
      .then((response) => {
        console.log("Response:", response.data); // Log the response data
        setOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error); // Log any errors
      });
  };

  const handleChangee = (newValue) => {
    let packageType = ""; // Initialize packageType

    // Check if newValue is an event object
    if (
      typeof newValue === "object" &&
      newValue.target &&
      newValue.target.value
    ) {
      // If newValue is an event object, extract company name from its target
      packageType = newValue.target.value;
    } else {
      // If newValue is a primitive value, directly assign it to packageType
      packageType = newValue !== null ? newValue : "";
    }

    // Update company_name in the form data
    setFormData((prevData) => ({
      ...prevData,
      PACKAGE_TYPE: packageType,
    }));

    // Only send a request to the backend if packageType is not empty
    if (packageType.trim() !== "") {
      axios
        .post("http://localhost:5002/package", {
          PACKAGE_TYPE: packageType,
        })
        .then((response) => {
          console.log(response.data);
          // Set the fetched company details in the form data

          // Extract relevant data from the response
          const { PACKAGE_NAME, SPORT_TYPE, DURATION, PRICE } = response.data;

          setFormData((prevData) => ({
            ...prevData,
            PACKAGE_NAME: PACKAGE_NAME,
            SPORT_TYPE: SPORT_TYPE,
            DURATION: DURATION,

            PRICE: PRICE,
          }));
        })
        .catch((error) => {
          console.error("Error fetching company data:", error);
          // Handle errors if needed
        });
    }
  };

  const title = "Student Update";

  return (
    <div className="background">
      <Header title={title} />

      <div style={{ display: "flex", flexDirection: "row",backgroundColor:"#eae7eb" }}>
        <SideBar1 />

        <Container style={{ padding: 0 }}>
          <div id="main">
            <form id="form" onSubmit={handleSubmit}>
              <br></br>

              <Grid container spacing={3} className="grid2">
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="NAME"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.NAME}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="SURNAME"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.SURNAME}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Sex</InputLabel>
                    <Select
                      label="Sex"
                      name="SEX"
                      onChange={handleChange}
                      value={FormData.SEX}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Grade"
                    name="GRADE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.GRADE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Age"
                    name="AGE"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.AGE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.PHONE_NUMBER}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian Name"
                    name="GUARDIAN_NAME"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.GUARDIAN_NAME}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Guardian PhNo."
                    name="GUARDIAN_PHONE_NUMBER"
                    type="number"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.GUARDIAN_PHONE_NUMBER}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Relation</InputLabel>
                    <Select
                      label="Relation"
                      name="RELATIONSHIP"
                      onChange={handleChange}
                      value={formData.RELATIONSHIP}
                    >
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="ADDRESS"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.ADDRESS}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="City"
                    name="CITY"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.CITY}
                  />
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="State"
                    name="STATE"
                    variant="outlined"
                    onChange={handleChange}
                    value={formData.STATE}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      label="Status"
                      name="STATUS"
                      onChange={handleChange}
                      value={formData.STATUS}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="In Active">In Active </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* ////////////////////////////////////////////////////////////////// */}
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                  >
                    Class Information
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      handleChangee(newValue);
                    }}
                    options={options.map((option) => option.PACKAGE_TYPE)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Package Type"
                        name="package_type"
                        value={formData.PACKAGE_TYPE}
                        onChange={handleChangee}
                        required
                      />
                    )}
                    freeSolo
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Sport Type</InputLabel>
                    <Select
                      label="Sport Type"
                      name="SPORT_TYPE"
                      value={formData.SPORT_TYPE}
                      onChange={handleChange}
                    >
                      <MenuItem value="Badminton">Badminton</MenuItem>
                      <MenuItem value="Swimming">Swimming</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    label="Package Name"
                    type="text"
                    name="PACKAGE_NAME"
                    value={formData.PACKAGE_NAME}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    label="Duration"
                    type="text"
                    name="DURATION"
                    value={formData.DURATION}
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    name="START_DATE"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="End Date"
                    name="END_DATE"
                    type="date"
                    variant="outlined"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Price"
                    type="number"
                    name="PRICE"
                    variant="outlined"
                    value={formData.PRICE}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    fullWidth
                    label="Discount"
                    type="number"
                    name="DISCOUNT"
                    variant="outlined"
                    value={formData.DISCOUNT}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <TextField
                    label="Total Amount"
                    type="number"
                    name="TOTAL_AMOUNT"
                    variant="outlined"
                    value={formData.TOTAL_AMOUNT}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Renewed</InputLabel>
                    <Select
                      label="Renewed"
                      name="RENEWED"
                      onChange={handleChange}
                      value={formData.RENEWED}
                    >
                      <MenuItem value="Yes">Yes </MenuItem>
                      <MenuItem value="No"> No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <br />

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default StudentUpdate1;