import React, { useState} from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,

} from "@mui/material";
import "../ComponentsCSS/AdminDashboard.css";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import axios from "axios";
import SideBar1 from "../Components/SideBar1";



const Products1 = () => {

  // preparation of useState , declare the variables
  const [formData, setFormData] = useState({
    PACKAGE_TYPE:"",
    PACKAGE_NAME:"",
    SPORT_TYPE: "",
    DURATION: "",
    PRICE:"",
  });

  // update the input values to the variable
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // providing the api to insert the data into database , pass the values to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:5002/products", formData);
      console.log(response.data);
      console.log("successfully inserted");
      window.alert("successfully submitted");

    } catch (error) {
      console.error("Error submitting form:", error);
      window.alert("unable to submit");
    }
  };






  const title = "Package Details";

  // start of the creation of UI
  return (
    <div className="background">
      <Header title={title} />

      <div style={{ display: "flex", flexDirection: "row" , backgroundColor:"#eae7eb" }}>
        <SideBar1 />

        <Container style={{ padding: 0 }}>
          <div id="main">
           

            <form id="form" onSubmit={handleSubmit} >
              <br></br>
              

              <Grid container spacing={3} className="grid2">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(0, 79, 120) 5%, rgb(28, 143, 166) 37%, rgb(255 254 254) 100%)",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "white",
                      paddingLeft: "20px",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Package Details
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Package Type"
                    name="PACKAGE_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Package Name"
                    name="PACKAGE_NAME"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="SPORT TYPE"
                    name="SPORT_TYPE"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Duration"
                    name="DURATION"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item xs={11} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    label="Price"
                    name="PRICE"
                    variant="outlined"
                    onChange={handleChange}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

             
               <br />

                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{width: "20%", backgroundColor: "#306ef3"}}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Products1;