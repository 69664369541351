import React from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
} from "@mui/material";
// import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import '../ComponentsCSS/ManageButton.css';
import SideBar1 from "../Components/SideBar1";

function Attendance1() {
  const data = [
    { id: 1, name: "Kim", date :"05/04/2024", checkIn: "8:00 AM", checkOut: "10:00 AM", duration: "2 hrs", remarks: "NA" },
    { id: 2, name: "john", date :"05/04/2024", checkIn: "6:00 AM", checkOut: "8:00 AM", duration: "2 hrs", remarks: "10 mins late" },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#325c67",
      color: theme.palette.common.white,
      padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f3f3f3",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="background">
      <Header title="Attendance Table" />
      <div style={{ display: "flex", flexDirection: "row", backgroundColor:"#eae7eb" }}>
        <SideBar1 />
        <Container className="table-container" sx={{ margin: 0, padding: 0, maxWidth: "100%" }}>
          <div id="main">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  <TextField
                    style={{ width: "224px" }}
                    label="Search by Student Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                </Stack>
              </Stack>
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>Student Name</StyledTableCell>
                    <StyledTableCell>present_Date</StyledTableCell>
                    <StyledTableCell>Check In </StyledTableCell>
                    <StyledTableCell>Check Out</StyledTableCell>
                    <StyledTableCell>Duration</StyledTableCell>
                    <StyledTableCell>Remarks</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((rowData) => (
                    <StyledTableRow key={rowData.id}>
                      <StyledTableCell>{rowData.id}</StyledTableCell>
                      <StyledTableCell>{rowData.name}</StyledTableCell>
                      <StyledTableCell>{rowData.date}</StyledTableCell>
                      <StyledTableCell>{rowData.checkIn}</StyledTableCell>
                      <StyledTableCell>{rowData.checkOut}</StyledTableCell>
                      <StyledTableCell>{rowData.duration}</StyledTableCell>
                      <StyledTableCell>{rowData.remarks}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button variant="contained" color="primary"
           onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}  style={{ backgroundColor: "#306ef3 "}}>
            Scroll To Top
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default Attendance1;
