import React from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import jsPDF from "jspdf";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import '../ComponentsCSS/Reports.css'

import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

const data = [
 
  // { value: 10, label: 'Block A' },
  // { value: 15, label: 'Block B' },
  // { value: 20, label: 'Block C' },

  { value: 10, label: 'Block A' },
  { value: 15, label: 'Block B' },
  { value: 20, label: 'Block C' },
];

const size = {
  width: 400,
  height: 200,
};

const Report = () => {
  const handlePersonalDetailsPDF = () => {
    const doc = new jsPDF();
    // Add your logic to generate PDF for personal details section
    doc.save("personal_details.pdf");
  };

  const handlePackagesPDF = () => {
    const doc = new jsPDF();
    // Add your logic to generate PDF for packages section
    doc.save("packages.pdf");
  };

  const handleAssetsPDF = () => {
    const doc = new jsPDF();
    // Add your logic to generate PDF for assets section
    doc.save("assets.pdf");
  };

  const title = "Reports";

  return (
    <div className="background main-container">
      <Header title={title} />
      <div style={{ display: "flex", flexDirection: "row" ,backgroundColor:"#eae7eb" }}>
        <SideBar />
        <div className="main-cards size">


    <div className="row-cards">

            <div className="card-r">
              <div className="card-inner">
                <h3 style={{ fontSize: "20px" }}>Student Data</h3>{" "}
                <h1 style={{ fontSize: "20px" }}>500</h1>
              </div>
            </div>

            <div className="card-r">
              <div className="card-inner">
                <h3 style={{ fontSize: "20px" }}>Live Students</h3>
                <h1 style={{ fontSize: "20px" }}>20</h1>
              </div>
            </div>

            <div className="card-r extra">
              <div className="card-inner">
                <h3 style={{ fontSize: "20px" }}>Trainer</h3>{" "}
                <h1 style={{ fontSize: "20px" }}>12</h1>
              </div>
            </div>
          </div>

        {/* provided for the media query */}
          <div className="row-cards extras">
            <div className="card-r" >
              <div className="card-inner">
                <h3 style={{ fontSize: "20px" }}>Trainer</h3>{" "}
                <h1 style={{ fontSize: "20px" }}>12</h1>
              </div>
            </div>
          </div>

          

          <div className="row-cards">


            <div className="card-reports">
              <div className="card-inner-reports">
                <h3 style={{ color: "#524e4ef2" }}>Student Data</h3>
                <br />
                <Button variant="outlined" sx={{ marginRight: "5px" }}>
                  View
                </Button>
                <Button variant="outlined">Download</Button>
              </div>
            </div>

            <div className="card-reports">
              <div className="card-inner-reports">
                <h3 style={{ color: "#524e4ef2", paddingBottom: "20px" }}>
                  Attendance
                </h3>
                <Button variant="outlined" sx={{ marginRight: "5px" }}>
                  View
                </Button>
                <Button variant="outlined">Download</Button>
              </div>
            </div>
          </div>

          <div className="row-cards">
          <PieChart
                series={[
                  {
                    // arcLabel: (item) => `${item.label} (${item.value})`,
                    arcLabel: (item) => `(${item.value})`,
                    arcLabelMinAngle: 45,
                    data,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontWeight: 'bold',
                  },
                }}
                {...size}
           />
          </div>


        </div>
      </div>
    </div>
  );
};

export default Report;
