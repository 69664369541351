import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DashBoard from "./Pages/Dashboard";

import ManageMembers from "./Pages/ManageMembers";
import Report from "./Pages/Report";
import Attendance from "./Pages/Attendance";
import Users from "./Pages/Users";
import ManageEmployees from "./Pages/ManageEmployees";
import UpdateEmployee from "./Pages/UpdateEmployee";

import StudentData from "./Pages/StudentData";
import Packages from "./Pages/Packages";
import ManagePackage from "./Pages/ManagePackage";
import StudentUpdate from "./Pages/StudentUpdate";
import IntroScreen from "./Components/IntroScreen";

import Attendance1 from "./SecondPage/Attendance1";
import ManageMembers1 from "./SecondPage/ManageMembers1";
import ManageEmployees1 from "./SecondPage/ManageEmployees1";
import ManagePackage1 from "./SecondPage/ManagePackage1";
import Packages1 from "./SecondPage/Packages1";
import Report1 from "./SecondPage/Report1";
import Dashboard1 from "./SecondPage/Dashboard1";
import StudentData1 from "./SecondPage/StudentData1";
import StudentUpdate1 from "./SecondPage/StudentUpdate1";
import UpdateEmployee1 from "./SecondPage/UpdateEmployee1";
import Users1 from "./SecondPage/Users1";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 860,
      lg: 1050, // Change the width at the lg breakpoint
      xl: 1400,
    },
  },
});


function App() {


  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            
            <Route path="/StudentData" element={<StudentData/>} />
            <Route path="/ManageMembers" element={<ManageMembers/>} />
            <Route path="/Dashboard" element={<DashBoard />} />
       
            <Route path="/Report" element={<Report />} />
            <Route path="/Attendance" element={<Attendance/>} />
            <Route path="/Users" element={<Users/>} />
            <Route path="/ManageEmployees" element={<ManageEmployees/>} />
            <Route path="/UpdateEmployee/:ID" element={<UpdateEmployee/>} />
            <Route path="/Packages" element={<Packages/>} />
            <Route path="/ManagePackage" element={<ManagePackage/>} />
            <Route path="/StudentUpdate/:ID" element={<StudentUpdate/>} />
            <Route path="/IntroScreen" element={<IntroScreen/>} />


            <Route path="/Dashboard1" element={<Dashboard1 />} />
            <Route path="/Attendance1" element={<Attendance1/>} />
            <Route path="/ManageMembers1" element={<ManageMembers1/>} />
            <Route path="/ManageEmployees1" element={<ManageEmployees1/>} />
            <Route path="/ManagePackage1" element={<ManagePackage1/>} />
            <Route path="/Packages1" element={<Packages1/>} />
            <Route path="/Report1" element={<Report1 />} />
            <Route path="/StudentData1" element={<StudentData1/>} />
            <Route path="/StudentUpdate1/:ID" element={<StudentUpdate1/>} />
            <Route path="/UpdateEmployee1/:ID" element={<UpdateEmployee1/>} />
            <Route path="/Users1" element={<Users1/>} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
