import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
} from "@mui/material";
import axios from "axios";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import '../ComponentsCSS/ManageButton.css';


function ManagePackage() {
  const [userData, setUserData] = useState([]);
  const elementRef = useRef(null);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [arrowDisable, setArrowDisable] = useState(true);

    // start providing the fetch operation to take data from database
    useEffect(() => {
      axios.get("http://localhost:5002/fetchpackage").then((res) => {
            setUserData(res.data);
            console.log(res.data);
            setFilteredUserData(res.data); // filtered data with all sales data
            // window.alert("fetching of data done");
          })
          .catch((error) => {
              console.error("Error fetching user data:", error);
              // window.alert("unable to fetch the data");
              // Handle error (e.g., display a message to the user)
          });
  }, []);
  

    // end of display of the data


      // start of Filter sales data based on search query
        useEffect(() => {
        
            if (searchQuery.trim() === "") {
              setFilteredUserData(userData); // Reset to show all data if search query is empty
            } else {
            const filteredData = userData.filter((row) =>
                row.dsr_id.toString().includes(searchQuery.trim())
            );
            setFilteredUserData(filteredData);
            }
        }, [searchQuery, userData]);
      // end of providing the search option to the tables



    // start providing the arrow slide option to the table 
    
      const handleHorizantalScroll = (element, speed, distance, step) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
          element.scrollLeft += step;
          scrollAmount += Math.abs(step);
          if (scrollAmount >= distance) {
            clearInterval(slideTimer);
          }
          if (element.scrollLeft === 0) {
            setArrowDisable(true);
          } else {
            setArrowDisable(false);
          }
        }, speed);
      };
      // end of providing sliding to the table



        // providing the color to the tables 
        // below for the header
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
            // backgroundColor: "#388f91",
            backgroundColor: "#325c67",
            color: theme.palette.common.white,
            padding: "10px",
            },
            [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            padding: "10px",
            },
        }));

        // below one add color to the table body
            const StyledTableRow = styled(TableRow)(({ theme }) => ({
                "&:nth-of-type(odd)": {
                backgroundColor: "#f3f3f3",
                },
                // hide last border
                "&:last-child td, &:last-child th": {
                border: 0,
                },
            }));
        // end of adding color to the table


        const handleSearchInputChange = (event) => {
            setSearchQuery(event.target.value);
          };


        // start  To scroll from the bottom to top we go to below function
            const handleScrollToTop = () => {
                window.scrollTo({
                top: 0,
                behavior: "smooth", // Smooth scrolling behavior
                });
            };
        // End to scroll to bottom to top 

        const title = "Package Table";



  return (
    <div className="background">
      <Header title={title}/>
      <div style={{ display: "flex", flexDirection: "row", backgroundColor:"#eae7eb" }}>
        <SideBar />

        <Container
          className="table-container"
          sx={{ margin: 0, padding: 0, maxWidth: "100%" }}
        >
          <div id="main">
            <div className="Search">
              <Stack spacing={4}>
                <Stack spacing={5} direction={"row"}>
                  <TextField
                    style={{ width: "224px" }}
                    label="Search by Employee Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />

                  <Button
                    variant="contained"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      height:"35px",
                      marginTop:"4px",
                      backgroundColor: "#306ef3",
                      color: "white",
                    }}
                    component={Link}
                    to="/Packages"
                  >
                    {" "}
                    Add{" "}
                  </Button>

                    


                </Stack>
              </Stack>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                sx={{ margin: "10px 10px 10px 10px" }}
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, -10);
                }}
                disabled={arrowDisable}
              >
                <FaArrowLeft />
              </Button>
              <Button
                sx={{ margin: "10px 10px 10px 0px" }}
                variant="outlined"
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 20, 305, 10);
                }}
              >
                <FaArrowRight />
              </Button>
            </div>

            <TableContainer component={Paper} ref={elementRef}>
              <Table>
                <TableHead
                  sx={{
                    ".MuiTableCell-root": { fontWeight: "600" },
                  }}
                >
                  <TableRow>
                    <StyledTableCell>S.NO</StyledTableCell>
                    <StyledTableCell>Package Type</StyledTableCell>
                    <StyledTableCell>Package Name</StyledTableCell>
                    <StyledTableCell>Sport Type</StyledTableCell>
                    <StyledTableCell>Duration</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                {Array.isArray(filteredUserData) && filteredUserData.map((row) => (
                    <StyledTableRow key={row.ID}>
                      <StyledTableCell>{row.ID}</StyledTableCell>
                      <StyledTableCell>{row.PACKAGE_TYPE}</StyledTableCell>
                      <StyledTableCell>{row.PACKAGE_NAME}</StyledTableCell>
                      <StyledTableCell>{row.SPORT_TYPE}</StyledTableCell>
                      <StyledTableCell>{row.DURATION}</StyledTableCell>
                      <StyledTableCell>{row.PRICE}</StyledTableCell>
                     
                  

                      <StyledTableCell>
                        <button
                          style={{height:"25px"}}
                        >
                          <Link to={`/UpdateEmployee/${row.ID}`}>
                            {" "}
                            <EditCalendarIcon style={{ height:"15px"}}  />
                          </Link>
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleScrollToTop}
            // style={{width: "20%", backgroundColor: "#209e9d "}}
            style={{backgroundColor: "#306ef3", marginTop: "10px"}}
          >
            Scroll To Top
          </Button>
        </Container>
      </div>
    </div>
  );
}

export default ManagePackage;
