import React, { useEffect } from "react";
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
} from "react-icons/bs";
import Header from "../Components/Header";
// import SideBar from "../Components/SideBar";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { FaUser } from "react-icons/fa";
import { FaUsers,FaFile } from "react-icons/fa";
import elite from "../Components/elite1.png";
import skyline from "../Components/skyline.png";
import SideBar1 from "../Components/SideBar1";


const dataset = [
  {
    Attendance: 10,
    Day: "Monday",
  },
  {
    Attendance: 15,
    Day: "Tuesday",
  },
  {
    Attendance: 20,
    Day: "Wednesday",
  },
  {
    Attendance: 25,
    Day: "Thursday",
  },
  {
    Attendance: 30,
    Day: "Friday",
  },
  {
    Attendance: 40,
    Day: "Saturday",
  },
  {
    Attendance: 50,
    Day: "Sunday",
  },
];

const valueFormatter = (value) => `${value}`;

const getCustomTextStyle = () => ({
  fill: "red", // Change 'red' to your desired label color
});

const chartSetting = {
  yAxis: [
    {
      label: "No Of Students",
    },
  ],
  series: [{ dataKey: "Attendance", valueFormatter, color: "#626463", }],
   textStyle:{ fill: 'red' },
  height: 300,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
  },
};

function DashBoard1({ selectedLogo }) {
  const [tickPlacement, setTickPlacement] = React.useState("middle");
  const [tickLabelPlacement, setTickLabelPlacement] = React.useState("middle");
  const title = "Dashboard";

  // const selectedLogo = "selectedLogo";

  useEffect(() => {
    console.log("selectedLogo in Dashboard:", selectedLogo); // Check the value of selectedLogo
  }, [selectedLogo]);

  const getImageSource = () => {
    // Logic to get image source based on selectedLogo
    if (selectedLogo === "swimming") {
      return elite; // Replace with actual image path
    } else if (selectedLogo === "badminton") {
      return skyline; // Replace with actual image path
    } else {
      return "default_image_path"; // Replace with default image path
    }
  };
  

  return (
    <main className="main-container">
      <Header title={title} />

      <div style={{ display: "flex", flexDirection: "row", backgroundColor:"#eae7eb"}}>

   
      <SideBar1 selectedLogo={selectedLogo} />
      {/* <img src={selectedLogo === "badminton" ? elite  : skyline} alt="Logo" /> */}
      
        <div className="main-cards">
        
          <div className="row-cards">
            <div className="card">
              <div className="card-inner">
                <h3>Student Data</h3>
                <FaUser className="card_icon" />
              </div>
              <h1>500</h1>
            </div>
            <div className="card">
              <div className="card-inner">
                <h3>Live Students</h3>
                <FaUsers className="card_icon" />
              </div>
              <h1>20</h1>
            </div>

            <div className="card dispaly">
              <div className="card-inner">
                <h3>Monthly Enrollments</h3>
                <FaFile className="card_icon" />
              </div>
              <h1>12</h1>
            </div>
          </div>

          <div className="row-cards">
            <div className="card display">
              <div className="card-inner">
                <h3>Monthly Enrollments</h3>
                <FaFile className="card_icon" />
              </div>
              <h1>12</h1>
            </div>

            <div className="card dashboard_graph">
              <div className="card-inner">
                <h3 sx={{ fontSize: "15px" }}>Attendance </h3>
              </div>
              <BarChart
                dataset={dataset}
                xAxis={[
                  {
                    scaleType: "band",
                    type: "continuous",
                    dataKey: "Day",
                    tickPlacement,
                    tickLabelPlacement,
                  },
                ]}
                textStyle={getCustomTextStyle()} // Apply custom text style for labels
                {...chartSetting}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DashBoard1;