import React, { useState } from "react";
import "../ComponentsCSS/Login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "./elite.png";

const Login = () => {
  const navigate = useNavigate();
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // axios.defaults.withCredentials = true;
  // const [values, setValues] = useState({
  //   EMAIL_ID: "",
  //   PASSWORD: "",
  // });

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post("http://localhost:5002/login", values);
  //     // console.log(response.data.data);
  //     if (response.data.Status === "Success") {
  //       const userData = response.data.data;
  //       // console.log(response.data.data);
  //       const userRole = response.data.data.EMPLOYEE_TYPE;

  //       localStorage.setItem("userData", JSON.stringify(userData));
  //       localStorage.setItem("token", response.data.token);
  //       console.log(userData);
  //       if (userRole === "Admin") {
  //         navigate("/dashboard", {
  //           state: { name: response.data.data.name },
  //         });
  //       } else if (userRole === "Accountant") {
  //         navigate("/dashboard", {
  //           state: { name: response.data.data.name },
  //         });
  //       } else if (userRole === "Trainer") {
  //         navigate("/dashboard", {
  //           state: { name: response.data.data.name },
  //         });
  //       }
  //     } else {
  //       alert(response.data.Message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     alert("An error occurred while logging in");
  //   }
  // };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
 
   const handleSubmit = async (e) => {
      e.preventDefault();
      if (email === "Admin@gmail.com" && password === "123") {
        navigate("/IntroScreen");
      } else {
        alert("Invalid email or password");
      }
   };
  return (
    <>
      <div className="login">
        <div class="container" id="container">
          <div class="form-container sign-in-container">
          <form className="loginForm" onSubmit={handleSubmit}>

          <img id="logo" src={logo} alt="elite_logo" />
              <h1>Sign in</h1>

              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="email"
                name="email"
              />
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="password"
                name="password"
              />
              <button type="submit">submit</button>
            </form>
          </div>
{/* 
          <div class="overlay-container">
            <div class="overlay">
              <div class="overlay-panel overlay-right">
                <img id="logo" src={logo} alt="elite_logo" />
                <h1 id="hello">
                  Welcome to <br></br>Satya Sports Center
                </h1>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Login;